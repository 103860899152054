/**
 * Created by gschulz on 02.07.15.
 */

var CacheStorage = (function(){
    var _private = {},_public = {};

    /**
     *
     * @param {string|number} key
     * @param {*} value
     * @param {bool} [merge]
     */
    _public.setCacheByKey = function(key,value,merge){
        if(merge && !_.isUndefined(_private[key])){
            _private[key] = $.extend(true,{},_private[key],value);
            return;
        }
        _private[key] = value;
    };

    /**
     *
     * @param {string|number} key
     * @param {*} [fallBack]
     * @returns {*}
     */
    _public.getCacheByKey = function(key,fallBack){
        if(_.isUndefined(fallBack)){
            fallBack = {};
        }
        if(_.isUndefined(_private[key])){
            return fallBack;
        }
        return _private[key];
    };

    return _public;
})();